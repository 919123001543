<template>
  <b-container>
    <iq-card class="p-4 top-box">
    <b-row class="justify-content-center">
      <b-col lg="5">
        <div class="d-flex justify-content-center pb-3">
          <span class="title-colored-part text-style">{{allDetails.job}}</span>
        </div>
         <p class="text-initial d-block" v-html="allDetails.description"></p>
      </b-col>
      <b-col lg="7">
<!--          <div class="d-flex justify-content-center">-->
<!--            <span class="title-colored-part text-style">{{allDetails.job}}</span>-->
<!--          </div>-->
          <validationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(submit)" class="my-3 px-5">
            <b-row>
              <b-col lg="12" class="mb-2">
                <main-select v-model="selectedBranch" :options="allBranches" :reduce="branch => branch.id" :label="'name'"
                             :placeholder="'اختر الفرع'"></main-select>
              </b-col>
              <b-col lg="12" class="mb-2">
                <input-form
                    v-model="username"
                    :placeholder="'اسمك'"
                    :validate="'required'"
                    name="الاسم"
                />
              </b-col>
              <b-col lg="12" class="mb-2" v-for="(inputs,key) in allHireQeuestion" :key="key">
                <div class="mb-2" v-if="inputs.qtype === 'file'">
                  <span class="text-white mb-2 d-block text-initial ">{{inputs.question}}</span>
                  <b-form-file
                      v-model="inputs.answer"
                      :placeholder="$t('forms.dropFile')"
                      :drop-placeholder="$t('forms.dropFile')"
                      class="form-control"
                      @change="addFileToFormData($event, inputs.id)"
                  ></b-form-file>
                </div>
                <div v-else-if="inputs.qtype === 'yesno'">
                  <main-select v-model="inputs.answer" :options="['yes', 'no']"
                               :validate="'required'"
                               :placeholder="inputs.question"></main-select>
                </div>
                <input-form
                    v-else
                    v-model="inputs.answer"
                    :placeholder="inputs.question"
                    :validate="'required'"
                    :name="inputs.question"
                />
              </b-col>
            </b-row>
            <b-button variant="warning" type="submit" class="w-100" v-if="!loginSubmit">
              <span class="font-size-16">{{ $t('hiring.submitApp') }}</span>
            </b-button>
            <b-button variant="warning" class="w-100" v-else>
              <spinner-loading :text="$t('login.checking')" />
            </b-button>
          </b-form>
          </validationObserver>
      </b-col>
    </b-row>
    </iq-card>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import contactUsServices from '../services/contact-us.services'
const fd = new FormData()
export default {
  props: ['formDataQu'],
  data () {
    return {
      loginSubmit: false,
      allDetails: '',
      allHireQeuestion: [],
      allBranches: [],
      selectedBranch: '',
      username: ''
    }
  },
  methods: {
    addFileToFormData: function (e, obj) {
      /*    var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      fd.append('forms[0].id', obj)
      fd.append('forms[0].answer', files[0]) */
    },
    submit () {
      this.loginSubmit = true
      this.allHireQeuestion.forEach(function (value, key) {
        fd.append(`forms[${key}][id]`, value.id)
        fd.append(`forms[${key}][answer]`, value.answer)
      })
      if (this.selectedBranch !== 'null') {
        fd.append('branch', this.selectedBranch)
      }
      fd.append('name', this.username)
      contactUsServices.sendMyData(this.$route.params.id, fd).then((res) => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'hiring' })
      }).finally(() => { this.loginSubmit = false })
    },
    getAllBranches () {
      contactUsServices.getContactInfo().then(res => {
        this.allBranches = res.data.data.branches
        this.allBranches.unshift({ id: 'null', name: 'المقر الرئيسى' })
      })
    },
    getSpacificHiringData () {
      contactUsServices.getSpacificHiringData(this.$route.params.id).then(res => {
        this.allDetails = res.data.data[0]
        this.allHireQeuestion = res.data.data[0].hire_question.map(inputData => {
          return { ...inputData, answer: '' }
        })
      })
    }
  },
  mounted () {
    core.index()
    // this.allHireQeuestion = this.formDataQu.hire_question.map(inputData => {
    //   return { ...inputData, answer: '' }
    // })
  },
  created () {
    this.getAllBranches()
    this.getSpacificHiringData()
  }
}
</script>
<style>
</style>
